









































import { Component, Vue } from 'vue-property-decorator';
import AttachedNewProofOfTaxSituationSuccessfullyViewModel
  from '@/vue-app/view-models/components/document-validation/attached-new-proof-of-tax-situation-successfully-view-model';

@Component({ name: 'DocumentSuccessfullySent' })
export default class AttachedNewProofOfTaxSituationSuccessfully extends Vue {
  view_model = Vue.observable(new AttachedNewProofOfTaxSituationSuccessfullyViewModel());
}

