import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Router } from '@/modules/shared/domain/router';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AttachedNewProofOfTaxSituationSuccessfullyViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.ROUTER)
  readonly router!: Router;

  readonly i18n_namespace = 'components.document-validation.attached-new-proof-ot-tax-situation-successfully';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToLogin = () => {
    this.router.navigate('/login');
  }
}
